import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
import { ItalicText } from '../../components/italic-text/italic-text';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Web-Entwickler:innen haben es schwer. Du bekommst ein paar schriftliche Anforderungen und ein visuelles
Design. Du beginnst mit der Umsetzung: Du fügst HTML-Elemente hinzu, gestaltest sie mit CSS und
implementierst Geschäftslogik mit JavaScript. Du machst dir Gedanken über Usability, Web-Sicherheit,
Geschwindigkeit, Datenverwaltung und so weiter.`}</p>
    <p>{`Du atmest tief durch und sagst zu dir selbst: `}<ItalicText mdxType="ItalicText">{`„Ich schaffe das!“`}</ItalicText></p>
    <p>{`Plötzlich ruft dein Projektmanager an: `}<ItalicText mdxType="ItalicText">{`„Die Website wird doch barrierefrei sein, oder?“`}</ItalicText>{`
Du gerätst in Panik und tippst das Wort Barrierefreiheit in die Suchmaschine ein. Du wirst mit Informationen
überflutet. Einige davon sind widersprüchlich. Du bittest ChatGPT um Hilfe. Es zeigt dir übertrieben
komplexen Code, der mit `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA"
      }}>{`ARIA-Attributen`}</a>{`
gespickt ist. Seufz! Du denkst: `}<ItalicText mdxType="ItalicText">{`„Das wird eine Menge Arbeit!“`}</ItalicText></p>
    <p>{`Ich habe gute Nachrichten für dich: Barrierefreiheit muss nicht anstrengend sein. Sie kann sogar ganz einfach
und, ich wage zu behaupten, wunderschön sein. Die `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Web_platform"
      }}>{`Web-Plattform`}</a>{`
ist dein Verbündeter. Lass sie einfach die Arbeit machen!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFA//EABcBAAMBAAAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAZbCrukzzUF//8QAGRABAAMBAQAAAAAAAAAAAAAAAQACEQMi/9oACAEBAAEFAtCzTw1xDZyy1VGf/8QAFREBAQAAAAAAAAAAAAAAAAAAAQD/2gAIAQMBAT8BJb//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARL/2gAIAQIBAT8BrL//xAAcEAACAQUBAAAAAAAAAAAAAAAAAQIQERIhMVH/2gAIAQEABj8CbEvd7OUcpK+MTh//xAAcEAADAAEFAAAAAAAAAAAAAAAAASERMUFhgdH/2gAIAQEAAT8hai30hgRuAQGojLhMt4j0MvZ//9oADAMBAAIAAwAAABCIL//EABgRAQEAAwAAAAAAAAAAAAAAAAEAESEx/9oACAEDAQE/ENusI4Bv/8QAGBEAAwEBAAAAAAAAAAAAAAAAABEhATH/2gAIAQIBAT8Q6g+1n//EAB0QAQEAAgIDAQAAAAAAAAAAAAERACFBcVGB0fD/2gAIAQEAAT8Q2wQtaVw5kAh1rqk4x0oe/uE0bjepMFosU+ROssFU/eM//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Code-Zeilen werden auf eine Frau projiziert, die in die Kamera blickt.",
          "title": "Code-Zeilen werden auf eine Frau projiziert, die in die Kamera blickt.",
          "src": "/static/470253b68565527bb5cfb191581b60a0/e5166/pexels-thisisengineering-code-woman.jpg",
          "srcSet": ["/static/470253b68565527bb5cfb191581b60a0/f93b5/pexels-thisisengineering-code-woman.jpg 300w", "/static/470253b68565527bb5cfb191581b60a0/b4294/pexels-thisisengineering-code-woman.jpg 600w", "/static/470253b68565527bb5cfb191581b60a0/e5166/pexels-thisisengineering-code-woman.jpg 1200w", "/static/470253b68565527bb5cfb191581b60a0/b17f8/pexels-thisisengineering-code-woman.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © ThisIsEngineering / pexels.com`}</em></p>
    <p>{`Wir werden einen kurzen Blick auf die Grundlagen des Webs werfen und wie man sie effizient einsetzt.
Dann gebe ich euch einige konkrete Beispiele für Web-Features mit eingebauter Barrierefreiheit.`}</p>
    <h2>{`Die grundlegenden Werkzeuge des Accessibility Engineering`}</h2>
    <p>{`Die Web-Plattform ist eine Sammlung von Technologien, die vom `}<a parentName="p" {...{
        "href": "https://www.w3.org/"
      }}>{`World Wide Web Consortium`}</a>{`
und anderen Gremien als offene Standards entwickelt wurden. Zu diesen Technologien gehören HTML, CSS,
JavaScript und WAI-ARIA. Diese Bausteine des Webs ermöglichen es uns, Websites zu gestalten, die für
alle Menschen zugänglich sind.`}</p>
    <p>{`Warum tun sich so viele Web-Entwickler:innen dann mit Barrierefreiheit so schwer?
Ich sehe dafür zwei Hauptgründe:`}</p>
    <ul>
      <li parentName="ul">{`Übermäßige Abhängigkeit von externen Bibliotheken.`}</li>
      <li parentName="ul">{`Unzureichende Erfahrung mit nativen Web-Features.`}</li>
    </ul>
    <p>{`Die meisten Junior-Developer lernen heutzutage Webentwicklung auf der Grundlage von Frameworks wie
`}<a parentName="p" {...{
        "href": "https://angular.dev/"
      }}>{`Angular`}</a>{`, `}<a parentName="p" {...{
        "href": "https://react.dev/"
      }}>{`React`}</a>{` oder `}<a parentName="p" {...{
        "href": "https://vuejs.org/"
      }}>{`Vue.js`}</a>{`. Sie lernen
alles über Komponenten und Datenmanagement, machen sich aber kaum mit nativen HTML-Elementen und
fortgeschrittenen CSS-Features vertraut.`}</p>
    <p>{`Ich mache ihnen keinen Vorwurf! Moderne JavaScript-Frameworks und UI-Bibliotheken bieten mächtige Funktionen,
die uns bei der Implementierung komplexer Webanwendungen helfen. Aber sie sind eine weitere Ebene der
Abstraktion. Sie entfernen uns von den wesentlichen Bausteinen des Webs. Was können wir also tun?`}</p>
    <h3>{`HTML First und die Regel der geringsten Macht`}</h3>
    <p>{`Die `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Rule_of_least_power"
      }}>{`Regel der geringsten Macht`}</a>{` besagt, dass man die
am wenigsten leistungsfähige Sprache wählen sollte, die für einen bestimmten Zweck geeignet ist. Was heißt
das für Web-Entwickler:innen? Wenn du etwas mit HTML machen kannst, verwende HTML. Du musst das Styling
anpassen? Verwende CSS. Etwas ist nicht allein mit HTML und CSS machbar? Dann verwende JavaScript und
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/standards-guidelines/aria/"
      }}>{`WAI-ARIA`}</a>{`.`}</p>
    <p>{`Native HTML-Elemente sind so konzipiert, dass sie in verschiedenen Browsern und mit diversen assistiven
Technologien wie Screenreadern funktionieren. Sie sind in der Regel robuster und zuverlässiger als
benutzerdefinierte Lösungen. Wenn du das nächste Mal ein neues Feature implementierst, solltest du nach
einer Lösung in der folgenden Reihenfolge suchen:`}</p>
    <ol>
      <li parentName="ol">{`HTML`}</li>
      <li parentName="ol">{`CSS`}</li>
      <li parentName="ol">{`JavaScript`}</li>
      <li parentName="ol">{`WAI-ARIA`}</li>
      <li parentName="ol">{`Externe Bibliotheken`}</li>
    </ol>
    <p>{`Als erstes suche ich immer nach einer nativen Lösung in den `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/"
      }}>{`MDN Web Docs`}</a>{`.
Wenn ich etwas mit HTML und CSS allein nicht hinbekomme, recherchiere ich die richtige Verwendung von
ARIA-Attributen und JavaScript, um eine barrierefreie Lösung zu schaffen. Der
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/ARIA/apg/"
      }}>{`ARIA Authoring Practices Guide (APG)`}</a>{` bietet alle Infos, die du brauchst.`}</p>
    <p>{`Warum stehen externe Bibliotheken an letzter Stelle der Liste? Weil sie eine zusätzliche Abhängigkeit für
dein Projekt schaffen. Sobald du dich für eine UI-Komponentenbibliothek entschieden hast, bist du daran
gebunden. Deshalb achte unbedingt darauf, eine barrierefreie UI-Bibliothek zu verwenden. Schau dir am besten
meine `}<a parentName="p" {...{
        "href": "/de/accessible-ui-component-libraries.de/"
      }}>{`„Checkliste für barrierefreie UI-Komponenten-Bibliotheken“`}</a>{` an.`}</p>
    <h3>{`Der aktuelle Zustand des Webs`}</h3>
    <p>{`Die Web-Plattform entwickelt sich ständig weiter. Jeden Tag werden neue HTML-Elemente, CSS-Eigenschaften und
Web-APIs spezifiziert und von den Browsern implementiert. Initiativen wie `}<a parentName="p" {...{
        "href": "https://wpt.fyi/interop-2024"
      }}>{`Interop 2024`}</a>{`
tragen dazu bei, die Interoperabilität des Webs zu verbessern.`}</p>
    <p>{`Für Web-Entwickler:innen ist es heute viel einfacher und unkomplizierter, barrierefreie Websites zu erstellen
als noch vor 10 Jahren. Ich gebe zu, nicht alles lässt sich allein mit HTML und CSS bewerkstelligen. Im Moment
braucht man noch ARIA und JavaScript, um eine `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/ARIA/apg/patterns/tabs/"
      }}>{`Tab-Liste`}</a>{` oder
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/ARIA/apg/patterns/tooltip/"
      }}>{`benutzerdefinierte Tooltips`}</a>{` zu implementieren.`}</p>
    <p>{`Aber ich möchte mich auf das Positive konzentrieren! Werfen wir einen Blick auf einige native HTML-Features,
die es euch sehr einfach machen, robuste, barrierefreie Websites zu erstellen.`}</p>
    <h2>{`Beispiele für native HTML-Features`}</h2>
    <h3>{`Das `}<InlineCode mdxType="InlineCode">{`button`}</InlineCode>{`-Element: Ein mächtiger Allrounder`}</h3>
    <p>{`Vielleicht ist das `}<InlineCode mdxType="InlineCode">{`button`}</InlineCode>{`-Element schon älter als du! Es wurde erstmals im Dezember
1997 in der `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/REC-html40-971218/"
      }}>{`HTML 4.0-Spezifikation`}</a>{` definiert. Das heißt,
Schaltflächen gibt es schon eine ganze Weile. Dennoch unterschätzen viele Entwickler:innen dieses mächtige
und vielseitige HTML-Element. Manche halten es sogar für eine gute Idee,
stattdessen ein `}<InlineCode mdxType="InlineCode">{`div`}</InlineCode>{`-Element zu verwenden. Horror! 😱`}</p>
    <p>{`Vielleicht denkst du jetzt: `}<ItalicText mdxType="ItalicText">{`„Na gut, Romeo! Warum bist du so verliebt in das Button-Element?“`}</ItalicText>{`
Lass mich mit der `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button"
      }}>{`kurzen Beschreibung von MDN`}</a>{` antworten:`}</p>
    <blockquote lang="en">
    The &lt;button&gt; HTML element is an interactive element activated by a user with a mouse, keyboard, finger,
voice command, or other assistive technology. Once activated, it then performs an action, such as submitting
a form or opening a dialog.
    </blockquote>
    <p>{`Das `}<InlineCode mdxType="InlineCode">{`button`}</InlineCode>{`-Element wurde so konzipiert, dass es für alle Nutzer:innen gut funktioniert.
Du kannst es mit der Maus anklicken. Du kannst eine Schaltfläche mit der `}<InlineCode mdxType="InlineCode">{`TAB`}</InlineCode>{`-Taste
fokussieren und sie mit der Eingabe- oder Leertaste aktivieren. Wenn du mit einem Screenreader zu einem Button
navigierst, wird er dir sagen, dass es sich um eine Schaltfläche handelt, die aktiviert werden kann.`}</p>
    <p>{`Browser wenden auch Standard-Styles auf HTML-Schaltflächen an. Einige Web-Entwickler:innen empfinden das als
lästig, weil sie das Styling mit CSS überschreiben müssen. Da verwenden sie lieber
das `}<InlineCode mdxType="InlineCode">{`div`}</InlineCode>{`-Element und klatschen einen `}<InlineCode mdxType="InlineCode">{`click`}</InlineCode>{`-Event-Handler
darauf, weil sie glauben, das sei genug. Das ist es aber nicht!`}</p>
    <p>{`Es gibt große Unterschiede zwischen `}<InlineCode mdxType="InlineCode">{`button`}</InlineCode>{` und `}<InlineCode mdxType="InlineCode">{`div`}</InlineCode>{`-Elementen!
Eine Schaltfläche löst den `}<InlineCode mdxType="InlineCode">{`click`}</InlineCode>{`-Event-Handler für alle Arten der Interaktion aus:
Maus, Tastatur, Screenreader usw. Das `}<InlineCode mdxType="InlineCode">{`div`}</InlineCode>{`-Element hingegen wird nur bei Maus- oder
Touch-Interaktion ausgelöst. Um ein `}<a parentName="p" {...{
        "href": "https://theadminbar.com/accessibility-weekly/divs-are-not-buttons/"
      }}>{`div-Element in eine barrierefreie Schaltfläche`}</a>{`
zu verwandeln, ist folgendes notwendig:`}</p>
    <ul>
      <li parentName="ul">{`Einen `}<InlineCode mdxType="InlineCode">{`tabindex`}</InlineCode>{` hinzufügen, um das Element fokussierbar zu machen.`}</li>
      <li parentName="ul">{`Einen sichtbaren Fokusindikator anzeigen, wenn die Schaltfläche den Fokus erhält.`}</li>
      <li parentName="ul">{`Tastatur-Event-Handler für die Enter- und Leertaste definieren.`}</li>
      <li parentName="ul">{`Ein `}<InlineCode mdxType="InlineCode">{`role`}</InlineCode>{`-Attribut hinzufügen, um Screenreader-Nutzer:innen mitzuteilen,
dass es sich um eine Schaltfläche handelt.`}</li>
    </ul>
    <p>{`Wow, das sind eine Menge Arbeit und viele unnötige Code-Zeilen! Wenn du stattdessen eine native Schaltfläche
verwendest, ist dein Code einfacher zu lesen und zu pflegen. Immer noch nicht überzeugt? Dann lies meinen
Artikel `}<a parentName="p" {...{
        "href": "/de/3-ways-web-devs-benefit-accessibility.de/"
      }}>{`„3 Gründe, warum auch Web-Entwickler:innen von Barrierefreiheit profitieren“`}</a>{`.`}</p>
    <p>{`Das ist schon eine Menge Text über Buttons. Aber es gibt noch eine Sache, die ich erwähnen möchte:
Native Schaltflächen bekommen laufend mehr Superkräfte!`}</p>
    <p>{`Das letzte Upgrade verdanken wir der neuen `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/Popover_API"
      }}>{`Popover-API`}</a>{`.
Du kannst das `}<InlineCode mdxType="InlineCode">{`popovertarget`}</InlineCode>{`-Attribut verwenden, um das `}<InlineCode mdxType="InlineCode">{`button`}</InlineCode>{`-Element
in einen Popover-Steuerungs-Button zu verwandeln. Hier eine Demo für Menü-Buttons
(funktioniert derzeit nur in Chromium-Browsern):`}</p>
    <iframe title="Accessible Menu with Popover API" src="https://codepen.io/alexlehner86/embed/rNQmERq?default-tab=result" loading="lazy">
    See the Pen <a href="https://codepen.io/alexlehner86/pen/rNQmERq">
    Accessible Menu with Popover API</a> by Alexander Lehner (<a href="https://codepen.io/alexlehner86">@alexlehner86</a>)
    on <a href="https://codepen.io">CodePen</a>.
    </iframe>
    <p>{`Es gibt so viele Anwendungsfälle für Buttons mit angehängten Popovers: Menüs, Tooltips, Datumsauswahlen usw.
Wenn das Thema für dich neu ist, kannst du einen Blick auf die folgenden Artikel von mir werfen:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/de/popover-api-accessibility.de/"
        }}>{`„Einfach herausragend! Die neue Popover API und CSS Anchor Positioning“`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/de/accessible-navigation-menu.de/"
        }}>{`„Barrierefreie Navigationsmenüs mit der Popover API erstellen“`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/de/native-dialog-and-popover.de/"
        }}>{`„Native Dialoge und die Popover API — Das solltet ihr wissen“`}</a></li>
    </ul>
    <p>{`Aber das ist noch nicht alles! Das nächste Button-Upgrade beschert uns die (derzeit noch experimentelle)
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/Invoker_Commands_API"
      }}>{`Invoker Commands API`}</a>{`. Diese
API bietet die Möglichkeit, Schaltflächen deklarativ Aktionen zuzuweisen, wie das Öffnen oder Schließen eines
modalen Dialogs. Genial! 🥰`}</p>
    <p>{`Na schau an, was für ein Zufall! Dialoge sind das nächste native HTML-Feature auf meiner Liste.`}</p>
    <h3>{`Das `}<InlineCode mdxType="InlineCode">{`dialog`}</InlineCode>{`-Element: Immer oben auf`}</h3>
    <p>{`Dialoge sind ein wesentlicher Bestandteil moderner Web-Oberflächen: Von einfachen Aufforderungen, eine Aktion
zu bestätigen, bis hin zu Fenstern mit komplexen Inhalten. Mit dem nativen `}<InlineCode mdxType="InlineCode">{`dialog`}</InlineCode>{`-Element
ist es ganz einfach, einen barrierefreien Modal-Dialog über den anderen Seiteninhalten zu öffnen.`}</p>
    <iframe src="https://alexlehner86.github.io/fancy-css-playground/#/dialog" title="Demo Native Dialog Element" loading="lazy"></iframe>
    <p>{`Das Dialog-Element ist standardmäßig barrierefrei. Wenn du die Methode `}<InlineCode mdxType="InlineCode">{`showModal()`}</InlineCode>{`
verwendest, um einen modalen Dialog zu öffnen, geschieht Folgendes:`}</p>
    <ul>
      <li parentName="ul">{`Der Browser setzt den Fokus automatisch auf das erste interaktive Element innerhalb des Dialogs.`}</li>
      <li parentName="ul">{`Ein Screenreader weist darauf hin, dass der neue Inhalt Teil eines Dialogs ist.`}</li>
      <li parentName="ul">{`Solange der Modal-Dialog geöffnet ist, können Nutzer:innen mit dem restlichen Inhalt der Seite nicht
interagieren. Das bedeutet, dass Tastaturnutzer:innen den Dialog nicht mit der `}<InlineCode mdxType="InlineCode">{`TAB`}</InlineCode>{`-Taste
verlassen können. Auch der virtuelle Cursor des Screenreaders kann sich nur innerhalb des Dialogs bewegen.`}</li>
      <li parentName="ul">{`Der Modal-Dialog kann standardmäßig mit der `}<InlineCode mdxType="InlineCode">{`ESC`}</InlineCode>{`-Taste geschlossen werden.`}</li>
      <li parentName="ul">{`Nach dem Schließen wird der Fokus automatisch zurück auf das Bedienelement gesetzt, mit dem der Dialog
geöffnet wurde. Davon profitieren Tastatur- und Screenreader-Nutzer:innen, die sofort an der Stelle
weitermachen können, wo sie aufgehört hatten.`}</li>
    </ul>
    <p>{`Natürlich musst du nicht unbedingt das native `}<InlineCode mdxType="InlineCode">{`dialog`}</InlineCode>{`-Element verwenden. Du kannst
auch ein benutzerdefiniertes, barrierefreies Dialogfeld mit ARIA und JavaScript implementieren. Aber warum
solltest du das tun? Komm schon! Sei clever und lass die Web-Plattform die Arbeit machen. 😉`}</p>
    <p>{`Wenn du mit dem nativen `}<InlineCode mdxType="InlineCode">{`dialog`}</InlineCode>{`-Element nicht vertraut bist und mehr darüber erfahren
möchtest, lies meinen Artikel `}<a parentName="p" {...{
        "href": "/de/native-dialog-element.de/"
      }}>{`„Warum du das native Dialog-Element nutzen solltest“`}</a>{`.`}</p>
    <h3>{`Formularfelder: Gute Zeiten, schlechte Zeiten`}</h3>
    <p>{`Allzu oft verwenden Web-Entwickler:innen benutzerdefinierte Formularfelder, um Designanforderungen zu erfüllen –
und vernachlässigen dabei die Barrierefreiheit. Das können wir besser!`}</p>
    <p>{`Mit modernen Browsern kannst du auf die meisten
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Learn_web_development/Extensions/Forms/Basic_native_form_controls"
      }}>{`nativen Formular-Elemente`}</a>{`
dein eigenes Styling anwenden. Auf diese Weise können wir barrierefreie Webformulare erstellen, die auf
allen Plattformen ein einheitliches Aussehen haben.`}</p>
    <p>{`Native Formular-Elemente wie `}<InlineCode mdxType="InlineCode">{`input`}</InlineCode>{` oder `}<InlineCode mdxType="InlineCode">{`select`}</InlineCode>{` sind
standardmäßig barrierefrei. Sie erhalten den Tastaturfokus und können mit der Eingabetaste oder der Leertaste
aktiviert werden, z.B. um eine Checkbox auszuwählen. Sie kommunizieren ihre Rolle und ihren aktuellen Status
an Screenreader.`}</p>
    <p>{`Die folgende Demo zeigt ein barrierefreies Webformular mit eigenem Styling für die native Auswahlliste und
die Radio-Buttons:`}</p>
    <iframe title="Styling Native Form Controls" src="https://codepen.io/alexlehner86/embed/mdqGLxZ?default-tab=result" loading="lazy">
    See the Pen <a href="https://codepen.io/alexlehner86/pen/mdqGLxZ">
    Styling Native Form Controls</a> by Alexander Lehner (<a href="https://codepen.io/alexlehner86">@alexlehner86</a>)
    on <a href="https://codepen.io">CodePen</a>.
    </iframe>
    <p>{`Leider lassen sich nicht alle nativen Formular-Elemente leicht mit benutzerdefinierten Styles anpassen.
Mozilla bietet einen tollen Artikel über die `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Learn_web_development/Extensions/Forms/Styling_web_forms"
      }}>{`Gestaltung von Webformularen`}</a>{`,
den ich sehr empfehlen kann. Darin werden native Formular-Elemente in drei Gruppen eingeteilt: „Die Guten“,
„Die Schlechten“ und „Die Hässlichen“.`}</p>
    <p>{`Meiner Meinung nach sind die nativen Elemente für Eingabefelder, Radio-Buttons, Checkboxen und einfache
Auswahllisten sehr gut gestaltbar. Es gibt keinen Grund, stattdessen benutzerdefinierte Elemente zu
verwenden. Es sei denn, du hast eine masochistische Veranlagung. Mehr Infos findest du in meinem Artikel
`}<a parentName="p" {...{
        "href": "/de/styling-accessible-web-forms.de/"
      }}>{`„Barrierefreie Web-Formulare mit individuellem Design gestalten“`}</a>{`.`}</p>
    <p>{`Und was ist mit dem Rest? Einige native Formular-Elemente verfügen über interne Elemente, die nicht allein
mit CSS gestaltet werden können. Dazu gehören der Farbwähler, der Datumswähler, die Datei-Auswahl und
das `}<InlineCode mdxType="InlineCode">{`select`}</InlineCode>{`-Element. In diesen Fällen verstehe ich den Bedarf an benutzerdefinierten
Komponenten. Stellt nur sicher, dass sie barrierefrei sind.`}</p>
    <p>{`Zum Glück für uns wird das `}<InlineCode mdxType="InlineCode">{`select`}</InlineCode>{`-Element bald ein wichtiges Upgrade erhalten.
Eine anpassbare Version des Elements befindet sich offiziell in `}<a parentName="p" {...{
        "href": "https://github.com/whatwg/html/issues/9799"
      }}>{`Phase 2 der WHATWG`}</a>{`,
mit starkem Cross-Browser-Interesse und einem in Chrome Canary verfügbaren Prototypen. Weitere Informationen
findest du in diesem `}<a parentName="p" {...{
        "href": "https://developer.chrome.com/blog/rfc-customizable-select"
      }}>{`Chrome-Blogbeitrag`}</a>{`. 🤩`}</p>
    <h3>{`Disclosure- und Akkordeon-Widgets mit nativem HTML`}</h3>
    <p>{`Das native HTML-Element `}<InlineCode mdxType="InlineCode">{`details`}</InlineCode>{` erzeugt ein Disclosure-Widget, dessen Inhalt nur
dann sichtbar ist, wenn das Widget geöffnet wurde. Der Inhalt des
verschachtelten `}<InlineCode mdxType="InlineCode">{`summary`}</InlineCode>{`-Elements liefert das Label für das Disclosure-Widget.
Die perfekten HTML-Elemente für eine FAQ-Seite oder einen ausklappbaren Info-Abschnitt in einem Artikel.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<details>
    <summary>What is HTML?</summary>
    HTML is the standard markup language for web pages, defined by the W3C and WHATWG.
</details>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Früher war es nicht möglich, den `}<InlineCode mdxType="InlineCode">{`display`}</InlineCode>{`-Typ des `}<InlineCode mdxType="InlineCode">{`details`}</InlineCode>{`-Elements
zu ändern. Diese Einschränkung wurde in Chromium-Browsern bereits gelockert, so dass du Grid- oder
Flex-Layouts verwenden kannst.
Der Artikel `}<a parentName="p" {...{
        "href": "https://developer.chrome.com/blog/styling-details"
      }}>{`„More options for styling `}{`<`}{`details`}{`>`}{`“`}</a>{`
enthält eine wirklich coole Demo für ein exklusives Akkordeon.`}</p>
    <h2>{`Fazit`}</h2>
    <p>{`Also gut. Dieser Artikel ist viel länger geworden, als ich beabsichtigt hatte. 😂`}</p>
    <p>{`Ich hoffe, er hilft dir auf deinem Weg zum Accessibility Engineer. Die wichtigste Botschaft:
Native HTML-Elemente sind einfach klasse und (meistens) von Haus aus barrierefrei. Nutze sie!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      